import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import splitArray from '../../../../utils/splitArray';
import { UsersList } from '../../interfaces/UsersList';

type SplitedUsersArray = Array<UsersList[]> | [[]];

interface UseLoadUsersInterface {
  setUsersSplited: Dispatch<SetStateAction<Array<UsersList[]>> | [[]]>;
  setFilteredUsers: Dispatch<SetStateAction<UsersList[] | []>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

type UsersFromApiResponse = {
  id: string;
  empresa_suspenso?: string | null;
  data_ativacao?: string | null;
  email: string;
  nome: string;
  celular: string;
  associated_company?: string;
  object_id: string;
}

export default function useLoadUsers({
  setUsersSplited,
  setFilteredUsers,
  setCurrentPage,
}: UseLoadUsersInterface) {
  const [fullUsers, setFullUsers] = useState<UsersList[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date | string>('');
  const [authenticationType, setAuthenticationType] = useState<string>('');

  const { user } = useAppContext();
  const { readOnly } = user;
  const { apiCall } = useApiCall();

  const loadUsers = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.listUsers,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error && apiResponse.error !== 'sem usuarios') {
          toast.error(`Não foi possível carregar a lista dos colaboradores (${apiResponse.error})`, {
            toastId: 'loadUsersFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const usersList: UsersFromApiResponse[] = apiResponse?.result;
        const mappedUsersList = usersList?.map((collaborator) => ({
          id: readOnly ? collaborator.nome : collaborator.id,
          email: readOnly && collaborator.email,
          cellphone: readOnly && collaborator.celular,
          dataAtivacao: collaborator.data_ativacao || 'Nunca ativado',
          // eslint-disable-next-line no-nested-ternary
          status: (collaborator.empresa_suspenso
            ? 'Suspenso'
            : (
              collaborator.data_ativacao
                ? 'Ativo'
                : 'Registrado'
            )),
          // eslint-disable-next-line no-nested-ternary
          codStatus: (collaborator.empresa_suspenso
            ? 2
            : (
              collaborator.data_ativacao
                ? 1
                : 3
            )),
          associatedCompany: collaborator.associated_company,
          _id: collaborator.object_id,
        }));
        console.log(mappedUsersList);
        setFullUsers(mappedUsersList);
        setLastUpdate(apiResponse.lastUpdate);
        setAuthenticationType(apiResponse.authenticationType);

        const splitedArray: SplitedUsersArray = splitArray(mappedUsersList);
        setUsersSplited(splitedArray);
        setFilteredUsers(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a lista dos colaboradores',
      catchAction: () => setDoesListApiHasError(true)
    });
  }, [apiCall, readOnly, setCurrentPage, setFilteredUsers, setUsersSplited]);

  return {
    fullUsers,
    doesListApiHasError,
    isLoadLoading,
    loadUsers,
    lastUpdate,
    authenticationType,
  };
}
